<script setup>
import { onMounted, ref, computed, watch, onUnmounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useWindowHeight } from '@/util/common-utils';
import { decryptData } from '@/dataService/dataService';
import { commonActionJitsi } from '@/util/common-constant';
import VueTypes from 'vue-types';
const props = defineProps({
  name: VueTypes.string.def(''),
  message: VueTypes.string.def(''),
  idTrinhsat: VueTypes.string.def(''),
  maxHeight: VueTypes.number.def(600),
  keyClass: VueTypes.string.def(''),
});
const { windowHeight } = useWindowHeight();
const api = ref(null);
const { state } = useStore();
const itemValue = computed(() => state.coordinator.itemValue || '');
const userInfo = computed(() => state.auth.userInfo || '');
const params = new URLSearchParams(window.location.search);
const { dispatch } = useStore();
const emit = defineEmits(['leaveRoom', 'openChat']);
const isLeaveRoom = ref(false);
const listActions = ref([
  {
    key: 'mic',
    icon: require('@/static/img/icon/mic-call.svg'),
    isSelect: true,
    isAction: true,
  },
  {
    key: 'video',
    icon: require('@/static/img/icon/video-call.svg'),
    isSelect: true,
    isAction: true,
  },
  {
    key: 'chat',
    icon: require('@/static/img/icon/chat-call.svg'),
    isSelect: true,
    isAction: true,
  },
  {
    key: 'close',
    icon: require('@/static/img/icon/close-call.svg'),
    isSelect: true,
    isAction: false,
  },
]);
const initJitsi = () => {
  if (window.JitsiMeetExternalAPI) {
    if (api.value) {
      api.value.dispose();
    }

    api.value = new window.JitsiMeetExternalAPI(
      decryptData(window.VUE_APP_API_JITSI),
      {
        roomName: props.idTrinhsat,
        parentNode: document.getElementById('jitsi-container'),
        userInfo: {
          displayName: userInfo.value.fullName || 'Unknown User',
        },
        configOverwrite: {
          disableInviteFunctions: true,
          disableRemoteMute: true,
          disableRemoteControl: true,
          disableProfile: true,
          prejoinPageEnabled: false,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_BRAND_WATERMARK: false,
          SHOW_POWERED_BY: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          TOOLBAR_BUTTONS: [],
        },
      }
    );
    api.value.addEventListener('videoConferenceLeft', (event) => {
      handleLeaveRoom(props.idTrinhsat);
    });
    api.value.addEventListener('outgoingMessage', (event) => {
      handleSendMessages(event.message);
    });
    api.value.addEventListener('participantJoined', async (event) => {});
  } else {
    console.error('JitsiMeetExternalAPI is not available!');
  }
};
onMounted(initJitsi);
watch(
  () => props.idTrinhsat,
  (newDialogId, oldDialogId) => {
    if (newDialogId !== oldDialogId) {
      initJitsi();
    }
  }
);

const handleLeaveRoom = async (id) => {
  if (!api.value) return;
  await dispatch('coordinator/leaveRoom', { roomId: id });
  api.value.dispose();
  api.value = null;
  isLeaveRoom.value = true;
  dispatch('coordinator/handleSetComponent', null);
  params.delete('trinhSatId');
  params.delete('type');
  const newUrl =
    window.location.pathname +
    (params.toString() ? `?${params.toString()}` : '');

  // Cập nhật URL mà không reload trang
  window.history.replaceState({}, '', newUrl);
};
const handleSendMessages = (message) => {
  let mess = {
    receiverId: itemValue.value.otherInfo.peerId,
    receiverType: itemValue.value.otherInfo.peerType,
    messageType: 'text',
    content: message,
    forwarded: false,
  };
  dispatch('coordinator/sendMessages', mess).then((data) => {});
};

const getSelectState = (key) => {
  const item = listActions.value.find((action) => action.key === key);
  return item ? item.isSelect : false;
};
const handleSelectItem = (key) => {
  const item = listActions.value.find((action) => action.key === key);
  if (!item) return;

  item.isSelect = !item.isSelect;
  if (api.value && commonActionJitsi[item.key]) {
    api.value.executeCommand(commonActionJitsi[item.key]);
  }
  dispatch('coordinator/updateStatusCall', {
    roomId: itemValue.value.dialogId,
    camera: getSelectState('video'),
    microphone: getSelectState('mic'),
    screenShare: false,
    userId: itemValue.value.otherInfo.peerId,
  });
};
</script>

<template>
  <div
    class="relative mt-[10px]"
    :style="{ height: `${windowHeight - 120}px` }"
  >
    <div id="jitsi-container" class="w-full h-[88%]"></div>
    <div class="h-[12%] w-full bg-ems-gray800 flex items-end justify-start">
      <div
        class="h-[72px] w-full bg-ems-gray700 rounded-2xl flex items-center justify-around"
      >
        <div
          v-for="(item, index) in listActions"
          :key="index"
          :class="`h-[48px] w-[48px] rounded-full flex items-center justify-center cursor-pointer ${
            item.isAction
              ? item.isSelect
                ? 'bg-ems-gray500'
                : 'bg-ems-gray500'
              : 'bg-ems-main3'
          }`"
          @click="handleSelectItem(item.key)"
        >
          <img alt="icon" :src="item.icon" />
        </div>
      </div>
    </div>
  </div>
</template>
